import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Menu, Dropdown, Input, Badge, Spin } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';

import { signOut } from '~/store/modules/auth/actions';
import { ApplicationState } from '~/store';
import api from '~/services/api';

import './styles.css';

const { Search } = Input;

interface Props {
  children: React.ReactNode;
}

const { Header, Content, Footer, Sider } = Layout;

export default function DefaultLayout({ children }: Props) {
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const ref = useRef<any>(null);
  const dispatch = useDispatch();
  const user = useSelector((state: ApplicationState) => state.user);

  const [loading, setLoading] = useState(true);
  const [dashboard, setDashboard] = useState({
    clients: 0,
    cities: 0,
    payments: 0,
  });

  const handleSubmit = useCallback(
    (value: string) => {
      history.push({
        pathname: '/clients',
        search: `search=${value}`,
      });

      if (ref.current) {
        ref.current.blur();
      }
    },
    [history]
  );

  const handleClose = useCallback(() => {
    dispatch(signOut());
  }, [dispatch, signOut]);

  const Icon = useMemo(
    () => (collapsed ? MenuUnfoldOutlined : MenuFoldOutlined),
    [collapsed]
  );

  const userMenu = useMemo(() => {
    return (
      <Menu>
        <Menu.Item>
          <Link to="/logout">Sair</Link>
        </Menu.Item>
      </Menu>
    );
  }, [handleClose]);

  useEffect(() => {
    api
      .get('/dashboard')
      .then(response => {
        const { data } = response;
        setDashboard(data);
      })
      .catch(() => {
        // Empty
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <Menu theme="dark" className="main-right-sidebar-menu">
          <Menu.Item key="clients">
            <div className="menu-with-badge">
              Clientes
              {loading ? (
                <Spin size="small" />
              ) : (
                <Badge overflowCount={99999} count={dashboard.clients} />
              )}
            </div>
            <Link to="/clients" />
          </Menu.Item>
          <Menu.Item key="payments">
            <div className="menu-with-badge">
              Financeiro
              {loading ? (
                <Spin size="small" />
              ) : (
                <Badge overflowCount={99999} count={dashboard.payments} />
              )}
            </div>
            <Link to="/payments" />
          </Menu.Item>
          <Menu.Item key="payments-month">
            Clientes inadimplentes
            <Link to="/clients/overdues" />
          </Menu.Item>
          <Menu.Item key="cities">
            <div className="menu-with-badge">
              Cidades
              {loading ? (
                <Spin size="small" />
              ) : (
                <Badge overflowCount={99999} count={dashboard.cities} />
              )}
            </div>
            <Link to="/cities" />
          </Menu.Item>
          <Menu.Item key="logs">
            <div className="menu-with-badge">Histórico do sistema</div>
            <Link to="/logs" />
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header className="default-main-layout">
          <Icon className="trigger" onClick={() => setCollapsed(!collapsed)} />
          <div className="main-layout-right">
            <div>
              <Search
                ref={ref}
                className="right-input"
                placeholder="Buscar por um cliente"
                onSearch={handleSubmit}
                style={{ width: 200 }}
              />
            </div>
            <Dropdown
              className="right-menu"
              overlay={userMenu}
              trigger={['click']}
            >
              <div className="ant-dropdown-link">{user.name}</div>
            </Dropdown>
          </div>
        </Header>
        <Content>{children}</Content>
        <Footer style={{ textAlign: 'center' }}>
          Paiva e Paschoal ©2021 Criado por Higo Ribeiro
        </Footer>
      </Layout>
    </Layout>
  );
}
