import { action } from 'typesafe-actions';

import { Auth, AuthTypes, Credential } from './types';

export const loadRequest = (data: Credential) =>
  action(AuthTypes.LOAD_REQUEST, { data });

export const loadSuccess = (data: Auth) =>
  action(AuthTypes.LOAD_SUCCESS, { data });

export const loadFailure = () => action(AuthTypes.LOAD_FAILURE);

export const signOut = () => action(AuthTypes.SIGN_OUT);
