import { all, call, put, fork, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import api from '~/services/api';

import { loadSuccess, loadFailure, signOut } from './actions';
import { AuthTypes } from './types';
import { saveUserData } from '../user/actions';

export function* loadToken() {
  const token = localStorage.getItem('@Paiva&Paschoal:token');

  if (!token) {
    return;
  }

  yield put(loadSuccess({ token }));
}

export function* signIn({
  payload: {
    data: { email, password },
  },
}: AnyAction) {
  try {
    const { data } = yield call(api.post, 'sessions', { email, password });

    const { token } = data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(loadSuccess(data));
  } catch (err) {
    yield put(loadFailure());
  }
}

export function* signInSuccess({
  payload: {
    data: { token },
  },
}: AnyAction) {
  api.defaults.headers.Authorization = `Bearer ${token}`;

  try {
    const tokenExists = !!localStorage.getItem('@Paiva&Paschoal:token');

    if (!tokenExists) {
      localStorage.setItem('@Paiva&Paschoal:token', token);
    }

    const { data } = yield call(api.get, 'account');

    yield put(saveUserData(data));
  } catch (err) {
    if (err.response) {
      yield put(signOut());
    } else {
      yield put(loadFailure());
    }
  }
}

export function signOutSaga() {
  api.defaults.headers.Authorization = null;
  localStorage.removeItem('@Paiva&Paschoal:token');
}

export default all([
  fork(loadToken),
  takeLatest(AuthTypes.LOAD_REQUEST, signIn),
  takeLatest(AuthTypes.LOAD_SUCCESS, signInSuccess),
  takeLatest(AuthTypes.SIGN_OUT, signOutSaga),
]);
