import React from 'react';
import { Pagination } from 'antd';

interface Page {
  total: number;
  perPage: number;
  page: number;
  lastPage: number;
  onPageChange: (page: number, pageSize?: number) => void;
}

function itemRender(
  page: number,
  type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
  originalElement: React.ReactElement<HTMLElement>
): React.ReactNode {
  if (type === 'prev') {
    return <a>Anterior</a>;
  }
  if (type === 'next') {
    return <a>Próximo</a>;
  }
  return originalElement;
}

export default function MyPagination({ total, page, onPageChange }: Page) {
  if (total < 50) {
    return null;
  }

  return (
    <Pagination
      current={page}
      total={total}
      itemRender={itemRender}
      defaultCurrent={page}
      onChange={onPageChange}
      defaultPageSize={50}
    />
  );
}
