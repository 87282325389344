import React, { useState, useEffect } from 'react';
import { IMaskInput } from 'react-imask';
import classNames from 'classnames';

import 'antd/es/input/style/css';

interface MaskInputProps {
  mask?: any;
  blocks?: any;
  value?: string;
  defaultValue?: string;
  unmask?: boolean;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

const MaskInput: React.FC<MaskInputProps> = ({
  value = '',
  mask,
  blocks,
  unmask,
  onChange,
  disabled,
  ...props
}) => {
  const [masked, setMasked] = useState<string>();

  const triggerChange: (c: string) => void = changedValue => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const onAccept: (v: string) => void = (v: string) => {
    setMasked(v);
    triggerChange(v);
  };

  useEffect(() => {
    setMasked(value);
  }, [value]);

  return (
    <>
      <IMaskInput
        onAccept={onAccept}
        value={masked}
        mask={mask}
        blocks={blocks}
        unmask={unmask}
        type="text"
        id="test"
        className={classNames('ant-input', {
          'ant-input-disabled': disabled,
        })}
      />
    </>
  );
};

export default MaskInput;
