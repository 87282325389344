import ReactLoadable from 'react-loadable';

import { Loading } from '~/components';

export default function loadable(loader: any) {
  return ReactLoadable({
    loader,
    loading: Loading,
    delay: 2000,
  });
}
