import { Reducer } from 'redux';

import { AuthState, AuthTypes } from './types';

const INITIAL_STATE: AuthState = {
  data: {
    token: '',
  },
  signed: false,
  loading: false,
  error: false,
};

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.LOAD_REQUEST:
      return { ...state, loading: true };

    case AuthTypes.LOAD_SUCCESS:
      return {
        data: action.payload.data,
        error: false,
        loading: false,
        signed: true,
      };

    case AuthTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true };

    case AuthTypes.SIGN_OUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
