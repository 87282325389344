import React from 'react';
import { BrowserRouter, Switch, Route as RouteDom } from 'react-router-dom';

import Route from './Route';
import loadable from './loadable';

const Main = loadable(() => import('~/pages/Main'));
const Clients = loadable(() => import('~/pages/Clients'));
const Client = loadable(() => import('~/pages/Client'));
const Payment = loadable(() => import('~/pages/Payment'));
const Payments = loadable(() => import('~/pages/Payments'));
const Overdues = loadable(() => import('~/pages/Overdues'));
const Logs = loadable(() => import('~/pages/Logs'));
const Cities = loadable(() => import('~/pages/Cities'));
const SignIn = loadable(() => import('~/pages/SignIn'));
const SignUp = loadable(() => import('~/pages/SignUp'));
const Logout = loadable(() => import('~/pages/Logout'));

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={SignIn} />

        <Route path="/signup" exact component={SignUp} />

        <Route path="/dashboard" component={Main} isPrivate />

        <Route
          path="/clients/:id/payments/:paymentId"
          component={Payment}
          isPrivate
        />

        <Route path="/clients/overdues" exact component={Overdues} isPrivate />

        <Route path="/clients/:id" component={Client} isPrivate />

        <Route path="/clients" component={Clients} isPrivate />

        <Route path="/payments" component={Payments} isPrivate />

        <Route path="/cities" component={Cities} isPrivate />

        <Route path="/logs" component={Logs} isPrivate />

        <RouteDom path="/logout" component={Logout} />
      </Switch>
    </BrowserRouter>
  );
}
