import React from 'react';
import { LoadingComponentProps } from 'react-loadable';
import { Spin } from 'antd';

import './styles.css';

export default function Loading({
  pastDelay,
  isLoading,
}: LoadingComponentProps) {
  if (pastDelay || isLoading) {
    return (
      <div className="page-loading">
        <Spin />
      </div>
    );
  }

  return null;
}
