import React from 'react';

import './styles.css';

interface Props {
  children: React.ReactNode;
}

export default function AuthLayout({ children }: Props) {
  return <div id="auth-page-wrapper">{children}</div>;
}
