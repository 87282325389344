export enum UserTypes {
  SAVE_USER_DATA = '@user/SAVE_USER_DATA',
}

export interface User {
  name: string;
  email: string;
}

export interface UserState {
  readonly name: string;
  readonly email: string;
}
