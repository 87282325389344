import ptBr from 'antd/es/date-picker/locale/pt_BR';

const locale = {
  ...ptBr,
  lang: {
    ...ptBr.lang,
    yearPlaceholder: 'Por favor selecione um ano',
    quarterPlaceholder: 'Por favor selecione um quarto',
    monthPlaceholder: 'Por favor selecione um mês',
    weekPlaceholder: 'Por favor selecione a semana',
    // rangeYearPlaceholder: ['Ano inicial', 'Ano final'],
    // rangeMonthPlaceholder: ['Mês inicial', 'Mês final'],
    // rangeWeekPlaceholder: ['Semana inicial', 'Semana final'],
  },
  timePickerLocale: {
    ...ptBr.timePickerLocale,
    // rangePlaceholder: ['Hora inicial', 'Hora final'],
  },
};

export default locale;
