import React, { useState } from 'react';

import DeletableContext from '~/contexts/DeletableContext';

import './styles.css';

interface DeletableRowProps {
  'data-row-key': number;
}

const DeleteRow: React.FC<DeletableRowProps> = props => {
  const index = props['data-row-key'];

  const [deleting, setDeleting] = useState<number | null>(null);

  return (
    <DeletableContext.Provider value={{ index, setDeleting }}>
      {deleting === index ? (
        <tr className="deletable-row-disabled">{props.children}</tr>
      ) : (
        <tr {...props} />
      )}
    </DeletableContext.Provider>
  );
};

export default DeleteRow;
