import React from 'react';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'react-redux';

import DefaultLayout from '~/pages/_layouts/default';
import AuthLayout from '~/pages/_layouts/auth';
import { ApplicationState } from '~/store';

interface Props extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  isPrivate?: boolean;
}

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}: Props) {
  const signed = useSelector((state: ApplicationState) => state.auth.signed);
  const authenticated = localStorage.getItem('@Paiva&Paschoal:token') || signed;

  if (!authenticated && isPrivate) {
    const { pathname, search } = window.location;

    const parsed = queryString.parse(search);
    parsed.to = `${pathname}${search}`;

    const redirect = pathname
      ? `?${queryString.stringify({ to: `${pathname}${search}` })}`
      : '';

    return <Redirect to={`/${redirect}`} />;
  }

  if (authenticated && !isPrivate) {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    let redirectTo = '/dashboard';

    if (params.has('to')) {
      redirectTo = params.get('to') || '/dashboard';
    }

    redirectTo = localStorage.getItem('@Skylab:redirectTo') || redirectTo;

    localStorage.removeItem('@Skylab:redirectTo');

    return <Redirect to={redirectTo} />;
  }

  const Layout = authenticated ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
