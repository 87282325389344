import { Reducer } from 'redux';

import { UserState, UserTypes } from './types';

const INITIAL_STATE: UserState = {
  name: '',
  email: '',
};

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.SAVE_USER_DATA:
      return action.payload.data;

    default:
      return state;
  }
};

export default reducer;
