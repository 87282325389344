export enum AuthTypes {
  LOAD_REQUEST = '@auth/LOAD_REQUEST',
  LOAD_SUCCESS = '@auth/LOAD_SUCCESS',
  LOAD_FAILURE = '@auth/LOAD_FAILURE',
  SIGN_OUT = '@auth/SIGN_OUT',
}

export interface Auth {
  token: string;
}

export interface Credential {
  email: string;
  password: string;
}

export interface AuthState {
  readonly data: Auth;
  readonly signed: boolean;
  readonly loading: boolean;
  readonly error: boolean;
}
